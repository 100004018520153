<template>
  <!-- <vx-card title="Approval Customer Address"> -->
    <div class="vx-row mb-12">
      <div v-bind:class="[detail ? detailShow : '', detailHide]">
        <vs-table
          search
          stripe
          border
          description
          :sst="true"
          :data="table.data"
          :max-items="table.length"
          :total="table.total"
          @search="handleSearch"
          @change-page="handleChangePage"
          @sort="handleSort"
        >
          <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{ this.table.start }} - {{ this.table.end }} of
                  {{ this.table.total }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in table.limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{ item }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>
          <template slot="thead">
            <vs-th width="10%"></vs-th>
            <!-- <vs-th sort-key width="5%">No</vs-th> -->
            <vs-th sort-key="status">Status</vs-th>
            <vs-th sort-key="address">Address</vs-th>
            <vs-th sort-key="map_address">Map</vs-th>
            <vs-th sort-key="contact_name">Contact</vs-th>
            <!-- <vs-th sort-key>Work Operation</vs-th>
            <vs-th sort-key>Receive Time</vs-th> -->
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td class="whitespace-no-wrap">
                <feather-icon
                  title="Edit"
                  icon="EditIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  class="ml-2"
                  @click.stop="handleEdit(data[indextr].id ? data[indextr].id : data[indextr].ID)"
                />
                <!-- <feather-icon
                  title="Delete"
                  icon="TrashIcon"
                  svgClasses="w-5 h-5 hover:text-danger stroke-current"
                  class="ml-2"
                  @click.stop="handleDelete(data[indextr].id ? data[indextr].id : data[indextr].ID)"
                /> -->
              </vs-td>
              <!-- <vs-td :data="data[indextr].code">{{((length*page)-length )+ indextr+1}}</vs-td> -->
              <vs-td :data="data[indextr].status">
                <div v-if="data[indextr].status">
                  <div
                    v-for="item in JSON.parse(data[indextr].status)"
                    :key="item.day"
                  >
                    {{ item }}
                  </div>
                </div>
              </vs-td>
              <vs-td>
                Code :
                <b>{{ data[indextr].code }}</b
                ><br>
                Code NOO :
                <b>{{ data[indextr].code_noo }}</b
                >
                <br>Outlet Name : <b>{{ data[indextr].customer.name }}</b>
                <br/>Territory :
                <b
                  >{{ data[indextr].territory.code }}
                  {{ data[indextr].territory.name }}</b
                >
                <br />Zone :
                <b
                  >{{ data[indextr].zone.code }}
                  {{ data[indextr].zone.name }}</b
                >
                <br />Sales :
                <b>{{ data[indextr].sales.WorkIDNumber }} {{ data[indextr].sales.Name }}</b>
                <br /><br />Address :
                <b>{{ data[indextr].address }}</b>
                <br />Subdistrict :
                <b>{{ data[indextr].sub_district }}</b>
                <br />District :
                <b>{{ data[indextr].district }}</b>
                <br />City :
                <b>{{ data[indextr].city }}</b>
                <br />Province :
                <b>{{ data[indextr].province }}</b>
                <br />Country :
                <b>{{ data[indextr].country }}</b>
                <br />Postal Code :
                <b>{{ data[indextr].postal_code }}</b>
                <br />Status :
                <b>{{ data[indextr].status_active }}</b>
                <br />Status Order Block:
                <b v-if="tr.shiping_block == 0">-</b>
                <b v-else-if="tr.shiping_block == 1">Order Block</b>
                <b v-else-if="tr.shiping_block == 2">Return Block</b>
                <b v-else-if="tr.shiping_block == 3">All Block</b>
              </vs-td>
              <vs-td :data="data[indextr].code">
                Address :
                <b>{{ data[indextr].map_address }}</b>
                <br />Lat :
                <b>{{ data[indextr].latitude }}</b>
                <br />Long :
                <b>{{ data[indextr].longitude }}</b>
              </vs-td>
              <vs-td :data="data[indextr].code">
                PIC Name :
                <b>{{ data[indextr].customer.nik_name }}</b>
                <br />Phone :
                <b>{{ data[indextr].phone }}</b>
                <br />Mobile :
                <b>{{ data[indextr].contact_mobile }}</b>
                <br />Email :
                <b>{{ data[indextr].email }}</b>
                <br />Fax :
                <b>{{ data[indextr].fax }}</b>
              </vs-td>
              <!-- <vs-td>
                <div
                  v-for="item in data[indextr].work_operation
                    .work_operation_detail"
                  :key="item.id"
                >
                  {{ item.work_day.day }} ({{ item.work_time.start_time }} -
                  {{ item.work_time.end_time }})
                  <br />
                </div>
              </vs-td>
              <vs-td>
                <div
                  v-for="item in data[indextr].receive_time
                    .work_operation_detail"
                  :key="item.id"
                >
                  {{ item.work_day.day }} ({{ item.work_time.start_time }} -
                  {{ item.work_time.end_time }})
                  <br />
                </div>
              </vs-td> -->
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination
          style="padding-top: 5px"
          :total="table.totalPage"
          v-model="setPage"
        />
      </div>
      <!-- form -->
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[detail ? detailShow : '', detailHide]"
        >
          <vs-row>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                class="ml-auto"
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <vs-col
              class="vertical-divider"
              vs-offset="0"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="1"
            >
              <vs-button
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              ></vs-button>
            </vs-col>

            <!-- form component -->
            <form-component
              @close="handleClose"
              :customerAddressId="customerAddressId"
              :showButtonApproveReject="showButtonApproveReject"
            ></form-component>
          </vs-row>
        </div>
      </transition>
    </div>
  <!-- </vx-card> -->
</template>
<script>
import FormComponent from "./form";
export default {
  props: {
    statusApproval: {
      type: String,
    },
    showButtonApproveReject: {
      type: Boolean,
    },
  },
  components: {
    FormComponent,
  },
  data() {
    return {
      customer: {
        id: null,
        code: "",
        name: "",
      },

      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      customerAddressId: null,
      table: {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "created_at",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      },
    };
  },
  methods: {
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose() {
      this.getData();
      this.detail = false;
    },
    handleCreate() {
      this.customerAddressId = null;
      this.detail = true;
    },
    getData() {
      let customer_id = 0
      if (this.customer.id) {
        customer_id = this.customer.id
      }
      this.$vs.loading();
      this.$http
        .get("/api/oms/v1/sfa-master/customer-address-sfa", {
          params: {
            customer_id: customer_id,
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            status_approval: this.statusApproval
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            console.log(resp.data);
          }
        });
    },
    // getDataCustomer() {
    //   if (this.customer.id) {
    //     this.$http
    //       .get("/api/oms/v1/sfa-master/customer/" + this.customer.id)
    //       .then((resp) => {
    //         this.$vs.loading.close();
    //         if (resp.code == 200) {
    //           this.customer.code = resp.data.code;
    //           this.customer.name = resp.data.name;
    //         } else {
    //           this.$router.push("/sfa-master/customers");
    //         }
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //         this.$router.push("/sfa-master/customers");
    //       });
    //   }
    // },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleDelete(id) {
      console.log(id)
      this.customerAddressId = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Please confirm to delete this customer address data",
        accept: this.acceptDelete,
      });
    },
    acceptDelete() {
      this.$vs.loading();
      this.$http
        .delete("/api/v1/master/customer-address/" + this.customerAddressId)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The customer address data was successfully deleted",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.getData();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleEdit(id) {
      this.customerAddressId = id;
      this.detail = true;
    },
    handleBack() {
      this.$router.push("/approval/customer");
    },
  },
  mounted() {
    // this.customer.id = parseInt(this.$route.params.id);
    // this.getDataCustomer();
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>

 <style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>